import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import once from 'lodash/once';
import { bindActionCreators } from 'redux';

import {
	Chat,
	ChatFormButtonsComponents,
	ChatFormButtonsWrappers,
	ChatMessage,
	ChatMessageActionsComponent,
	ChatMessageType,
	ChatOtherComponents,
	ChatPlugins,
	ChatPlugin,
	ChatListHeaderSettingsType,
	ChatNameType,
	ChatMessagesHeaderSettingsType,
} from '@common/react/components/Chat/Chat';
import { getAvatar } from '@common/react/utils/getAvatar';
import * as ChatsState from '@common/react/components/Chat/Store/Chats';
import { List } from '@common/typescript/objects/List';

import { BaseParams } from '@common/react/objects/BaseParams';
import { BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';
import { getUserName } from '@common/react/utils/utils';
import { RegularMessagePlugin } from '@common/react/components/Chat/RegularMessagePlugin/RegularMessagePlugin';
import { FilePlugin } from '@common/react/components/Chat/FilesPlugin/FilesPlugin';
import { handleUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';
import { RequestType } from '@common/react/components/RequestProvider/RequestProvider';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

export interface ChatSettingsRequests {
	chat: string;
	chatUser: string;
	chatMessage: string;
	chatMessageAccess: string;
	typing: string;
	loadChats: string;
	loadMessages: string;
	getChat: string;
	completeChat?: string;
	removeMessage?: string;
	updateMessage?: string;
	chatEmojiReactionMessage?: string;
	getOrCreatePersonalChat: string;
}

export interface ChatSettingsNotificationTypes {
	chat: string;
	chatUser: string;
	chatMessage: string;
	chatMessageAccess: string;
	chatReaction: string;
	typing: string;
	updateChatCounterNotification: string;
	updateUserMessagesCountNotification: string;
	addChatFromArchive?: string;
}

export interface ChatSettingsUserSettings {
	useBrowserNotification: boolean;
	sound: boolean;
}

export interface ChatSettingsAvatarSettings {
	// maybe better return components with special interface
	getUserAvatar: (user: any) => React.ReactNode;
	getUserAvatarAtMention?: (user: any) => React.ReactNode;
	getChatAvatar: (chat: any, userId: number) => React.ReactNode;
	notificationAvatar: (state: any) => React.ReactNode;
	viewerAvatar?: (state: any) => React.ReactNode;
}

export interface ChatSettingsFormSettings {
	sendByEnter: boolean;
	underFormLabel: string;
	allowPasteImages: boolean;
	maxAttachmentsCount: number;
	maxMessageLength: number;
	waveColor?: string;
}

export interface ChatSettingsChatPageSettings {
	path: string;
	chatIdUrlKey: string;
	archive: string;
}

export interface ErrorHandlers {
	onChatsLoadError?: (err: string) => void;
	onChatMessagesLoadError?: (err: string) => void;
	onSaveMessageLoadError?: (err: string) => void;
	onChatMessageAccessError?: (err: string) => void;
	chatMessagesErrorComponent?: ({ reload }) => React.ReactNode;
}

export interface ChatSettingsProviderProps {
	children: any;
	storageName?: string;
	requests?: ChatSettingsRequests;
	notificationTypes?: ChatSettingsNotificationTypes;
	userSettings?: ChatSettingsUserSettings;
	avatarSettings?: ChatSettingsAvatarSettings;
	formSettings?: ChatSettingsFormSettings;
	formButtons?: Array<ChatFormButtonsComponents>;
	formTags?: Array<ChatPlugins>;
	headerButtons?: Array<ChatPlugins>;
	messageTypes?: Array<ChatMessageType>;
	otherComponents?: Array<ChatOtherComponents>;
	pageSettings?: ChatSettingsChatPageSettings;
	chatFormButtonsWrappers?: ChatFormButtonsWrappers;
	chatStoreSettings?: ChatStoreSettings;
	title?: string;
	withRemoteId?: boolean;
	chatsFilters?: BaseParams;
	chatListHeaderSettings?: ChatListHeaderSettingsType;
	renderChatName?: ChatNameType;
	messagesHeaderComponents?: ChatMessagesHeaderSettingsType;
	notificationHideDelay?: number;
	maxChatMessageNotificationCount?: number;
	messageActions?: Array<ChatMessageActionsComponent>;
	removeMessageConfirmation?: boolean;
	errorHandlers?: ErrorHandlers;
	emptyChatListMessage?: React.ReactNode | (({ filters, load }) => React.ReactNode);
	basePath?: string;
	messagesLoadMoreIndicator?: React.ReactNode;
	plugins?: PluginsDictionary;
	listComponent?: Array<ChatPlugins>;
	messageControl?: ChatPlugins;
	messageControlWrappers?: Array<ChatPlugins>;
	showActionsByHover?: boolean;
	showMessagesButtonInVideoModal?: boolean;
	openModalFromNotification?: boolean;
	openInModal?: boolean;
	onMessageClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, message: ChatMessage) => void;
	onMessageDoubleClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, message: ChatMessage, reply: (message) => void) => void;
	messageAttachments?: Array<ChatPlugins>;
	messageAttachmentsBefore?: Array<ChatPlugins>;
	sendButtonWrapper?: ChatPlugins;
	getUser?: () => BaseUserWithAvatar;
	request?: RequestType;
	onNotificationClick?: (message: ChatMessage, defaultHandle: () => void) => void;
	handleAddChatByNewMessage?: (message: ChatMessage, chatFilters) => boolean;
}

export type PluginsDictionary = {
	[key in ChatPlugins]?: ChatPlugin;
};

export interface ChatSettingsProviderContextState {
	requests: ChatSettingsRequests;
	plugins: PluginsDictionary;
	storageName: string;
	notificationTypes: ChatSettingsNotificationTypes;
	formSettings: ChatSettingsFormSettings;
	formButtons: Array<ChatFormButtonsComponents>;
	otherComponents: Array<ChatOtherComponents>;
	chatFormButtonsWrappers: ChatFormButtonsWrappers;
	pageSettings: ChatSettingsChatPageSettings;
	chatStoreSettings: ChatStoreSettings;
	title: string;
	avatarSettings: ChatSettingsAvatarSettings;
	formTags: Array<ChatPlugins>;
	withRemoteId: boolean;
	chatListHeaderSettings: ChatListHeaderSettingsType;
	messagesHeaderComponents: ChatMessagesHeaderSettingsType;
	notificationHideDelay: number;
	maxChatMessageNotificationCount: number;
	getUser: () => BaseUserWithAvatar;
	request: RequestType;
	messageActions?: Array<ChatMessageActionsComponent>;
	chatsFilters?: BaseParams;
	renderChatName?: ChatNameType;
	removeMessageConfirmation?: boolean;
	errorHandlers?: ErrorHandlers;
	emptyChatListMessage?: React.ReactNode | (({ filters, load }) => React.ReactNode);
	basePath: string;
	messagesLoadMoreIndicator?: React.ReactNode;
	listComponent?: Array<ChatPlugins>;
	messageControl?: ChatPlugins;
	messageControlWrappers?: Array<ChatPlugins>;
	showActionsByHover?: boolean;
	showMessagesButtonInVideoModal?: boolean;
	modalMode?: boolean;
	openModalFromNotification?: boolean;
	onMessageClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, message: ChatMessage) => void;
	onMessageDoubleClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, message: ChatMessage, reply: (message) => void) => void;
	openInModal?: boolean;
	messageAttachments?: Array<ChatPlugins>;
	messageAttachmentsBefore?: Array<ChatPlugins>;
	sendButtonWrapper?: ChatPlugins;
	reloadChatId?: number;
	onNotificationClick?: (message: ChatMessage, defaultHandle: () => void) => void;
	handleAddChatByNewMessage?: (message: ChatMessage, chatFilters) => boolean;
}

export interface ChatSettingsProviderContextActions {
	setChatsFilters: React.Dispatch<React.SetStateAction<BaseParams>>;
	setModalMode: (modalMode: boolean | ((value) => boolean)) => void;
	startChat: (data, requestName?: string, openChat?: boolean) => Promise<Chat>;
	setReloadChatId: (id: number | undefined | ((value) => any)) => void;
}

export interface ChatSettingsProviderContext {
	state: ChatSettingsProviderContextState;
	actions: ChatSettingsProviderContextActions;
}

export interface ChatStoreSettings {
	getActionCreators?: any;
	getChatStore: (store) => ({chats: List<Chat>, chatsLoaded: boolean});
	getMessages: (chatId) => (state) => (List<ChatMessage> | undefined);
	getCurrentChat: (state) => Chat | null;
	userUnviewedMessagesCountName: string;
}

export const getUserPick = (chat, userId): JSX.Element => {
	const users = chat.contacts.filter((user: BaseUserWithAvatar) => user.id !== userId);
	if (users.length === 1) {
		return <div className="chat-list__item-avatar" style={{ backgroundImage: `url(${getAvatar(users[0])})` }} title={getUserName(users[0])} />;
	}

	return <div className="chat-list__item-avatar-multiple">
		{users.slice(0, 4).map((user: BaseUserWithAvatar) =>
			<div
				className="chat-list__item-avatar chat-list__item-avatar_min"
				key={user.id}
				title={getUserName(user)}
				style={{ backgroundImage: `url(${getAvatar(user)})` }}
			/>)}
	</div>;
};

export const createChatSettingsProviderContext = once(() => React.createContext({} as ChatSettingsProviderContext));

export const useChatSettingsProviderContext: () => ChatSettingsProviderContext = () =>
	React.useContext(createChatSettingsProviderContext());

export const chatRequests = {
	chat: 'chat',
	chatUser: 'chatUser',
	chatMessage: 'chatMessage',
	chatMessageAccess: 'chatMessageAccess',
	typing: 'typing',
	completeChat: 'chatArchive',
	removeMessage: 'deleteChatMessage',
	updateMessage: 'changeChatMessage',
	chatEmojiReactionMessage: 'chatEmojiReactionSave',
	loadChats: 'chatList',
	loadMessages: 'chatMessageList',
	getChat: 'getChat',
	getOrCreatePersonalChat: 'getOrCreatePersonalChat',
};

export const ChatSettingsProvider: (p: ChatSettingsProviderProps) => React.ReactElement = (props) => {
	const {
		request: defaultRequest,
		getUser: defaultGetUser,
	} = useApplicationContext();
	const {
		children,
		storageName = 'chats',
		requests = chatRequests,
		notificationTypes = {
			chat: 'Chat',
			chatUser: 'ChatUser',
			chatMessage: 'ChatMessage',
			chatReaction: 'ChatEmojiReaction',
			chatMessageAccess: 'ChatMessageAccess',
			typing: 'Typing',
			updateChatCounterNotification: 'UpdateChatCounterNotification',
			updateUserMessagesCountNotification: 'UpdateUserMessagesCountNotification',
			addChatFromArchive: 'AddChatFromArchive',
		},
		onNotificationClick,
		formSettings = {
			sendByEnter: false,
			underFormLabel: '',
			allowPasteImages: false,
			maxAttachmentsCount: 5,
			maxMessageLength: 500,
		},
		formButtons = [],
		pageSettings = {
			path: '/chats',
			chatIdUrlKey: 'chatId',
			archive: 'archive',
		},
		chatFormButtonsWrappers = {},
		otherComponents = [],
		messageActions = [],
		avatarSettings = {
			getUserAvatar: (user) => <div
				className="chat-message-list-component__item-avatar"
				style={{ backgroundImage: `url(${getAvatar(user)})` }}
			/>,
			getUserAvatarAtMention: (user) => <div
				className="chat-message-list-component__item-avatar"
				style={{ backgroundImage: `url(${getAvatar(user)})` }}
			/>,
			getChatAvatar: getUserPick,
			notificationAvatar: (state) => <img
				src={state.avatar}
				alt="avatar"
				style={{ width: '100%', height: '100%', objectFit: 'contain' }}
			/>,
		},
		chatStoreSettings = {
			getChatStore: (state) => ({ chats: state.chats.chats, chatsLoaded: state.chats.chatsLoaded }),
			getMessages: (chatId) => (state) => state.chats.messages[chatId] || undefined,
			getCurrentChat: (state) => state.chats.currentChat,
			userUnviewedMessagesCountName: 'unviewedMessagesCount',
		} as ChatStoreSettings,
		title = 'Chats',
		formTags = [],
		renderChatName = undefined,
		messagesHeaderComponents = [],
		withRemoteId = false,
		chatsFilters: chatsFiltersProps = {},
		chatListHeaderSettings = [],
		notificationHideDelay = 5000,
		maxChatMessageNotificationCount = 5,
		removeMessageConfirmation = true,
		errorHandlers,
		emptyChatListMessage = 'There are no chats',
		basePath = '/',
		messagesLoadMoreIndicator,
		plugins = {
			[ChatPlugins.Files]: FilePlugin,
		} as any,
		listComponent = [],
		messageControlWrappers = [],
		messageControl,
		showActionsByHover = true,
		showMessagesButtonInVideoModal,
		openInModal,
		openModalFromNotification = openInModal,
		messageAttachments,
		messageAttachmentsBefore,
		sendButtonWrapper,
		onMessageDoubleClick,
		onMessageClick,
		request = defaultRequest,
		getUser = defaultGetUser,
		handleAddChatByNewMessage,
	} = props;
	const user = getUser();
	const ChatSettingsProviderContext = createChatSettingsProviderContext();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const actions = React.useMemo(() => bindActionCreators(
		(chatStoreSettings.getActionCreators || ChatsState.getActionCreators)(),
		dispatch,
	), []);
	const [modalMode, setModalMode] = React.useState(false);
	const [chatsFilters, setChatsFilters] = React.useState<BaseParams>(chatsFiltersProps);
	const { chatsLoaded } = useSelector(chatStoreSettings.getChatStore, shallowEqual);
	const [reloadChatId, setReloadChatId] = React.useState<number>();

	const startChat = (data, requestName: string = requests?.getOrCreatePersonalChat, openChat: boolean = true) => {
		return request<Chat>(requestName, data)
			.then((result) => {
				if (chatsLoaded) {
					actions.addChat(result, storageName);
					actions.selectChat(result, storageName);
				}
				(openInModal || !openChat) && handleUrl(
					{ chatId: result.id },
					location,
					navigate,
					undefined,
					'',
					true,
				);
				if (openChat) {
					if (openInModal) {
						setModalMode((prev) => true);
					} else {
						navigate({
							pathname: pageSettings.path,
							search: `?${pageSettings.chatIdUrlKey}=${result.id}`,
						});
						return result;
					}
				}
				handleUrl(
					{ chatId: result.id },
					location,
					navigate,
					undefined,
					'',
					true,
				);
				return result;
			});
	};

	const value: {state: ChatSettingsProviderContextState, actions: ChatSettingsProviderContextActions} = React.useMemo(() => ({
		state: {
			requests,
			storageName,
			notificationTypes,
			formSettings,
			formButtons,
			chatFormButtonsWrappers,
			otherComponents,
			pageSettings,
			avatarSettings,
			chatStoreSettings: { getActionCreators: ChatsState.getActionCreators, ...chatStoreSettings },
			title,
			formTags,
			renderChatName,
			withRemoteId,
			chatsFilters,
			chatListHeaderSettings,
			messagesHeaderComponents,
			notificationHideDelay,
			maxChatMessageNotificationCount,
			messageActions,
			removeMessageConfirmation,
			errorHandlers,
			emptyChatListMessage,
			basePath,
			messagesLoadMoreIndicator,
			plugins: {
				...plugins,
				[ChatPlugins.Regular]: {
					...RegularMessagePlugin,
					options: plugins?.[ChatPlugins.Regular]?.options ?? RegularMessagePlugin.options,
				},
			},
			listComponent,
			messageControl,
			messageControlWrappers,
			showActionsByHover,
			showMessagesButtonInVideoModal,
			modalMode,
			openModalFromNotification,
			openInModal,
			messageAttachments,
			messageAttachmentsBefore,
			sendButtonWrapper,
			onMessageDoubleClick,
			onMessageClick,
			reloadChatId,
			getUser,
			request,
			onNotificationClick,
			handleAddChatByNewMessage,
		},
		actions: {
			setChatsFilters: (values) => {
				setChatsFilters((prev) => ({ ...prev, ...values }));
			},
			setModalMode,
			startChat,
			setReloadChatId,
		},
	}), [modalMode, actions, chatsLoaded, chatsFilters, reloadChatId, user]);

	return (
		<ChatSettingsProviderContext.Provider value={value}>
			{children}
		</ChatSettingsProviderContext.Provider>
	);
};
