import React from 'react';

import { shallowEqual, useSelector } from 'react-redux';

import { LoginState } from '@common/react/store/Login';

import { STORAGE_KEYS } from '@app/objects/StorageKeys';
import { ApplicationState } from '@app/store';
import { User } from '@app/objects/User';

const UserStateTracker: React.FC = ({ children }) => {
	const { user } = useSelector<ApplicationState, LoginState<User>>((state) => state.login, shallowEqual);

	React.useEffect(() => {
		if (!user) {
			localStorage.removeItem(STORAGE_KEYS.CHECKOUT_UID);
		}
	}, [user]);

	return <>{children}</>;
};

export default UserStateTracker;
