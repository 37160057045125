import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import loadable from '@loadable/component';
import 'regenerator-runtime/runtime';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';
import AutocompleteFilter from '@common/react/components/Forms/AutocompleteFilter/AutocompleteFilter';

import { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';

import DashboardRoute from '@app/components/Routes/DashboardRoute';
import RouteWithFooter from '@app/components/Routes/RouteWithFooter';
import Layout from '@app/components/Layout';
import { getLocationName } from '@app/utils/Utils';
import { Location } from '@app/objects/Location';

const params = {
	fallback: <LogoLoaderPage />,
};

/* --------------Admin---------------*/
const Profile = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProfilePage" */
		'@app/components/Pages/Profile'
	)), params);
const Dashboard = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DashboardPage" */
		'@app/components/Pages/Admin/Dashboard/Dashboard'
	)), params);
const ChatsPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ChatsPage" */
		'@app/components/Pages/Admin/Chats/Chats'
	)), params);
const Questionnaires = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "QuestionnairesPage" */
		'@app/components/Pages/Admin/Invites/Questionnaires'
	)), params);
const ConsentForms = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConsentFormsPage" */
		'@app/components/Pages/Admin/Invites/ConsentForms'
	)), params);
const Instructions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InstructionsPage" */
		'@app/components/Pages/Admin/Invites/Instructions'
	)), params);
const Analyzes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AnalyzesPage" */
		'@app/components/Pages/Admin/Analyzes/Analyzes'
	)), params);
const Appointments = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentsPage" */
		'@app/components/Pages/Admin/Appointments/Appointments'
	)), params);
const AppointmentViewer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentViewerPage" */
		'@app/components/Pages/Admin/Appointments/Appointment'
	)), params);
const Documents = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DocumentsPage" */
		'@app/components/Pages/Admin/Documents/Documents'
	)), params);
const Doctors = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SearchPage" */
		'@app/components/Pages/Doctor/Doctors'
	)), params);
const Prescriptions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DocumentsPage" */
		'@app/components/Pages/Admin/Prescriptions/Prescriptions'
	)), params);
const Users = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Users" */
		'@app/components/Pages/Admin/Users/Users'
	)), params);
const ShortLinks = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ShortLinks" */
		'@app/components/Pages/Admin/ShortLinks/ShortLinks'
	)), params);
const AuthLinks = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AuthLinks" */
		'@common/react/components/Pages/AuthLinks/AuthLinks'
	)), params);
const AuthLinksFilters = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AuthLinksFilters" */
		'@commonTuna/react/components/Pages/Admin/AuthLinks/AuthLinksFilters'
	)), params);
const UserSessions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserSessions" */
		'@app/components/Pages/Admin/UserSessions/UserSessions'
	)), params);
const Notifications = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "NotificationsPage" */
		'@app/components/Pages/Admin/Notifications/Notifications'
	)), params);
const SystemNotifications = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SystemNotificationsPage" */
		'@app/components/Pages/Admin/Notifications/SystemNotifications'
	)), params);
const SystemNotificationTypes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SystemNotificationTypes" */
		'@app/components/Pages/Admin/Notifications/SystemNotificationTypes'
	)), params);
const Pages = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PagesPage" */
		'@app/components/Pages/Admin/Pages/Pages'
	)), params);
const PageEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageEditorPage" */
		'@app/components/Pages/Admin/Pages/PageEditor'
	)), params);
const Orders = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "OrdersPage" */
		'@app/components/Pages/Admin/Orders/Orders'
	)), params);
const Inquiries = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Inquiries" */
		'@app/components/Pages/Admin/Inquiries/Inquiries'
	)), params);
const Bills = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BillsPage" */
		'@app/components/Pages/Admin/Bills/Bills'
	)), params);
const Checkout = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CheckoutPage" */
		'@app/components/Pages/Admin/Checkout/Checkout'
	)), params);
const EmailTemplates = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmailTemplatesPage" */
		'@app/components/Pages/Admin/EmailTemplates/EmailTemplates'
	)), params);
const MarketingEmails = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "MarketingEmailsPage" */
		'@app/components/Pages/Admin/MarketingEmails/MarketingEmails'
	)), params);
const SmsLogs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "SmsLogs" */
			'@app/components/Pages/Admin/SmsLogs/SmsLogs'
		)),
	params,
);
const EmailLogs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "EmailLogs" */
			'@commonTuna/react/components/Pages/Admin/EmailLogs/EmailLogs'
		)),
	params,
);
const Ordering = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Ordering" */
		'@app/components/Pages/Admin/Ordering/Ordering'
	)), params);
const BaseHostedServices = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BaseHostedServicesPage" */
		'@app/components/Pages/Admin/BaseHostedServices/BaseHostedServices'
	)), params);
const PageAccesses = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageAccessesPage" */
		'@app/components/Pages/Admin/PageAccesses/PageAccesses'
	)), params);

/* ------------Admin end-------------*/

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Home" */
		'@app/components/Pages/Home/Home'
	)), params);
const Login = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LoginPage" */
		'@app/components/Pages/Login/Login'
	)), params);
const Recover = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RecoverPage" */
		'@app/components/Pages/Recover/Recover'
	)), params);
const Confirm = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmPage" */
		'@app/components/Pages/Confirm/Confirm'
	)), params);
const ContactSupport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ContactSupportPage" */
		'@app/components/Pages/ContactSupport/ContactSupport'
	)), params);
const Register = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegisterPage" */
		'@app/components/Pages/Register/Register'
	)), params);
const Confirmation = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmationPage" */
		'@app/components/Pages/Confirmation/Confirmation'
	)), params);
const ChangeEmailConfirmation = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ChangeEmailConfirmation" */
		'@app/components/Pages/Register/ChangeEmailConfirmation'
	)), params);
const CompanyPortalComponent = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PortalFromBBY" */
		'@app/components/Pages/FromBBY/CompanyPortal'
	)), params);
const DoctorPortalComponent = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorPortalComponent" */
		'@app/components/Pages/Doctor/Doctor'
	)), params);
const DoctorReview = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorReview" */
		'@app/components/Pages/Doctor/DoctorReview'
	)), params);
const Specialties = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specialties" */
		'@app/components/Pages/Specialties/Specialties'
	)), params);
const Locations = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Locations" */
		'@app/components/Pages/Region/Regions'
	)), params);
const LocationPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Region" */
		'@app/components/Pages/Region/Region'
	)), params);
const Specialty = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specialty" */
		'@app/components/Pages/Specialties/Specialty'
	)), params);
const Specials = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specials" */
		'@app/components/Pages/Specials/Specials'
	)), params);
const Special = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Special" */
		'@app/components/Pages/Specials/Special'
	)), params);
const Product = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Product" */
		'@app/components/Pages/Products/Product'
	)), params);
const Procedures = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Procedures" */
		'@app/components/Pages/Procedures/Procedures'
	)), params);
const ProcedurePage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProcedurePage" */
		'@app/components/Pages/Procedures/ProcedurePage'
	)), params);
const PrivacyPolicy = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PrivacyPolicy" */
		'@app/components/Pages/PrivacyPolicy/PrivacyPolicy'
	)), params);
const RegistrationArticle = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegistrationArticle" */
		'@app/components/Pages/RegistrationArticle/RegistrationArticle'
	)), params);
const Inquiry = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Inquiry" */
		'@app/components/Pages/Inquiry/Inquiry'
	)), params);
const ExpiredLink = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ExpiredLink" */
		'@app/components/Pages/ExpiredLink/ExpiredLink'
	)), params);
const ShoppingCart = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ShoppingCart" */
		'@app/components/Pages/ShoppingCart/ShoppingCart'
	)), params);
const QuickPay = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "QuickPayPage" */
		'@app/components/Pages/QuickPay/QuickPay'
	)), params);
const ErrorPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ErrorPage" */ '@common/react/components/Pages/ErrorPage/ErrorPage')), params);
const PaymentsProcessing = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PaymentsProcessing" */
		'@app/components/Pages/PaymentsProcessing/PaymentsProcessing'
	)), params);
const Clinics = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicsPage" */
		'@app/components/Pages/Clinics/Clinics'
	)), params);
const ClinicPortalComponent = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicPortalComponent" */
		'@app/components/Pages/Clinics/ClinicPortalComponent'
	)), params);

const UserPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserPage" */
		'@app/components/Pages/Admin/Users/User'
	)), params);

const dashboardRoutes = [
	{
		path: '/dashboard',
		component: Dashboard,
	},
	{
		path: '/profile',
		component: Profile,
		title: 'Profile',
	},
	{
		title: 'Chats',
		component: ChatsPage,
		path: '/chats',
	},
	{
		title: 'Questionnaires',
		component: Questionnaires,
		path: '/questionnaires/:page?',
	},
	{
		title: 'Consent|Medical Documents',
		component: ConsentForms,
		path: '/consent-forms/:page?',
	},
	{
		title: 'Patient Instructions',
		component: Instructions,
		path: '/instructions/:page?',
	},
	{
		title: 'Lab. Orders',
		component: Analyzes,
		path: '/analysis-list/:page?',
	},
	{
		title: 'Appointments',
		component: Appointments,
		path: '/appointment-list/:page?',
	},
	{
		title: 'Appointment',
		component: AppointmentViewer,
		path: '/appointment/:id',
	},
	{
		title: 'Health Records',
		component: Documents,
		path: '/document-list/:page?',
	},
	{
		title: 'Prescriptions',
		component: Prescriptions,
		path: '/prescriptions-list/:page?',
	},
	{
		title: 'Users',
		component: Users,
		path: '/user-list/:page?',
	},
	{
		title: 'Short Links',
		component: ShortLinks,
		path: '/shortLink-list/:page?',
	},
	{
		title: 'Entry Log Journal',
		component: UserSessions,
		path: '/user-session-list/:page?',
	},
	{
		title: 'Notifications',
		component: Notifications,
		path: '/notifications/:page?',
	},
	{
		title: 'Notifications',
		component: SystemNotifications,
		path: '/system-notifications/:page?',
	},
	{
		title: 'Notification Types',
		component: SystemNotificationTypes,
		path: '/notification-types/:page?',
	},
	{
		title: 'Pages',
		component: Pages,
		path: '/page-list/:page?',
	},
	{
		title: 'Page Editor',
		component: PageEditor,
		path: '/page-editor/:id',
	},
	{
		title: 'Inquiries',
		component: Inquiries,
		path: '/inquiry-list/:page?',
	},
	{
		title: 'Purchases',
		component: Orders,
		path: '/orders/:page?',
	},
	{
		title: 'Bills',
		component: Bills,
		path: '/bills/:page?',
	},
	{
		title: 'Checkout',
		component: Checkout,
		path: '/checkout/:uid?',
	},
	{
		title: 'Email Templates',
		component: EmailTemplates,
		path: '/email-templates',
	},
	{
		title: 'Marketing Emails',
		component: MarketingEmails,
		path: '/marketing-emails/:page?',
	},
	{
		title: 'Sms Logs',
		component: SmsLogs,
		path: '/smsLogs',
	},
	{
		title: 'Email Logs',
		component: () => <EmailLogs
			renderFilters={(filters, values, handleChange) => <AutocompleteFilter<Location>
				type="locationList"
				renderTitle={(location: Location) => getLocationName(location, true, true)}
				param="locationId"
				handleChangeAndRefresh={handleChange}
				value={values?.locationName}
				placeholder="Clinic..."
				loadOnFocus
			/>}
			showUser
		/>,
		path: '/emailLogs',
	},
	{
		title: 'Hosted Services',
		component: BaseHostedServices,
		path: '/base-hosted-service-list/:page?',
	},
	{
		title: 'Page Accesses',
		component: PageAccesses,
		path: '/page-access-list/:page?',
	},
	{
		title: 'Auth Links',
		component: (props) => <AuthLinks filters={<AuthLinksFilters />} />,
		path: '/authLink-list/:page?',
	},
	{
		component: (props) => <UserPage />,
		path: '/user/:id?',
	},
];

export const baseRoutes = (<>
	<Route path="/" element={<RouteWithFooter />}>
		<Route path="/" element={<Home />} />
		<Route path="/login" element={<Login />} />
		<Route path="/registration" element={<Register />} />
		<Route path="/confirmation" element={<Confirmation />} />
		<Route path="/recover" element={<Recover />} />
		<Route path="/confirm" element={<Confirm />} />
		<Route path="/change-email" element={<ChangeEmailConfirmation />} />
		<Route path="/doctors" element={<Doctors />} />
		<Route path="/doctors/:path?" element={<DoctorPortalComponent />} />
		<Route path="/doctor-review/:path?" element={<DoctorReview />} />
		<Route path="/contact-support" element={<ContactSupport />} />
		<Route path="/company/:path?" element={<CompanyPortalComponent />} />
		<Route path="/privacy-policy" element={<PrivacyPolicy />} />
		<Route path="/about-registration" element={<RegistrationArticle />} />
		<Route path="/specialties" element={<Specialties />} />
		<Route path="/locations" element={<Locations />} />
		<Route path="/locations/*" element={<LocationPage />} />
		<Route path="/procedures" element={<Procedures />} />
		<Route path="/procedures/*" element={<ProcedurePage />} />
		<Route path="/specialties/*" element={<Specialty />} />
		<Route path="/inquiry" element={<Inquiry />} />
		<Route path="/specials/*" element={<Special />} />
		<Route path="/specials" element={<Specials />} />
		<Route path="/product/*" element={<Product />} />
		<Route path="/shopping-cart" element={<ShoppingCart />} />
		<Route path="/ordering" element={<Ordering />} />
		<Route path="/expiredLink" element={<ExpiredLink />} />
		<Route path="/quick-pay" element={<QuickPay />} />
		<Route path="/payments-processing" element={<PaymentsProcessing />} />
		<Route path="/clinics" element={<Clinics />} />
		<Route path="/clinics/*" element={<ClinicPortalComponent />} />
	</Route>
	{dashboardRoutes.map((item) => <Route
		key={item.path}
		path={item.path}
		element={<DashboardRoute component={item.component} title={item.title} />}
	/>)}
	<Route
		path="/error"
		element={<NotFoundRoute
			title="Error"
			component={ErrorPage}
			loginRoute={DashboardRoute}
			openRoute={(props) => <RouteWithFooter {...props} withHeader />}
		/>}
	/>
	<Route
		path="*"
		element={<NotFoundRoute
			loginRoute={DashboardRoute}
			openRoute={(props) => <RouteWithFooter {...props} withHeader />}
		/>}
	/>
</>);

export const clientRoutes = (<Route element={<Layout />}>
	{baseRoutes}
</Route>);

export const routes = (<Layout>
	<Routes>
		{baseRoutes}
	</Routes>
</Layout>);
