import { ReducersMapObject } from 'redux';

import * as Item from '@common/react/store/Item';
import { BaseAppThunkAction, baseReducers } from '@common/react/store';
import { ApplicationStateWithChats, getReducer as getChatsReducer } from '@common/react/components/Chat/Store/Chats';
import { PageItemState, reducer as PageStateReducer } from '@common/react/store/PageItem';
import { VideoChatState, getReducer as getVideoChatReducer } from '@common/react/store/VideoChat';
import { ItemsState as ItemsProviderStoreState, getReducer as getIPStoreReducer } from '@common/react/store/ItemsProviderStore';

import { BaseInvite } from '@commonTuna/react/objects/BaseInvite';

import { User } from '@app/objects/User';
import { HeaderState, reducer as HeaderReducer } from '@app/store/HeaderSearch';
import { CountersState, reducer as CountersReducer } from '@app/store/Counters';
import { SearchFilterState, reducer as SearchFilterReducer } from '@app/store/SearchFilter';
import { UserRegistrationSteps } from '@app/components/Pages/Register/PatientMainForm';
import { BuildData } from '@app/objects/BuildData';

// The top-level state object
export interface ApplicationState extends ApplicationStateWithChats<User> {
	serverPage: PageItemState<any>;

	buildData: Item.ItemState<BuildData>;

	companyTemplateInvites: ItemsProviderStoreState<BaseInvite>;

	userRegistrationSteps: Item.ItemState<UserRegistrationSteps>;

	counters: CountersState;

	header: HeaderState;

	searchFilterData: SearchFilterState;

	videoChat: VideoChatState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,

	buildData: Item.getReducer<BuildData>('buildData'),

	serverPage: PageStateReducer<any>('serverPage'),

	chats: getChatsReducer(),

	companyTemplateInvites: getIPStoreReducer<BaseInvite>('companyTemplateInvites'),

	userRegistrationSteps: Item.getReducer<UserRegistrationSteps>('userRegistrationSteps'),

	counters: CountersReducer,

	header: HeaderReducer,

	searchFilterData: SearchFilterReducer,

	videoChat: getVideoChatReducer(),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = BaseAppThunkAction<TAction, User, ApplicationState>
