import * as React from 'react';
import { NavLink } from 'react-router-dom';

import TextWithTooltip from '@common/react/components/UI/TextWithTooltip/TextWithTooltip';

import { getMoneyString } from '@commonTuna/react/components/Utils';
import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';

import AddToCartButton from '@app/components/UI/AddToCartButton/AddToCartButton';
import { Special } from '@app/objects/Special';
import { LocationPortal } from '@app/objects/CompanyPortal';
import CardImageLoader from '@app/components/UI/CardImageLoader/CardImageLoader';
import SpecialModal from '@app/components/UI/SpecialModal/SpecialModal';
import WithPathLink from '@app/components/UI/WithPathLink/WithPathLink';
import { getLocationName } from '@app/utils/Utils';

import noImage from '@images/no-image.jpg';

interface SpecialCardProps {
	item: Special;
	location?: LocationPortal;
	lazyLoad?: boolean;
}

const SpecialName: React.FC<{name: string, className?: string}> = ({ name, className }) => {
	return <TextWithTooltip
		text={name}
		count={45}
		tooltipProps={{
			placement: 'topRight',
		}}
		className={className}
	/>;
};

export const SpecialLocation: React.FC<{text: string}> = ({ text }) => {
	return <TextWithTooltip
		text={text}
		count={38}
		tooltipProps={{
			placement: 'topRight',
		}}
	/>;
};

const preventDefault = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
	e.preventDefault();
	e.stopPropagation();
};

const SpecialCardContent: React.FC<SpecialCardProps & { setOpen }> = (props) => {
	const {
		item,
		location = undefined,
		lazyLoad,
		setOpen,
	} = props;
	const [loading, setLoading] = React.useState<boolean>(true);
	const handleOpen = (e) => {
		setOpen(true);
	};

	React.useEffect(() => {
		setLoading(false);
	}, []);

	// set item location from props for clinic page(where specials are without location)
	item.location = item.location || location;
	const locationName: string = getLocationName(item.location, false, true) as string;

	return <>
		<div className="special-card-head">
			<div className="special-modal-page" onClick={preventDefault}>
				<div className="special-modal-page__preview" onClick={handleOpen}>Fast Preview</div>
			</div>
			{loading && <CardImageLoader uniqueKey={item.id.toString()} />}
			<ImageWithSkeleton
				withLazy={lazyLoad}
				src={item.originalAvatar ? `/remote/${item.originalAvatar}` : noImage}
				alt={item.name}
				onLoad={() => setLoading(false)}
				hidden={loading}
			/>
		</div>
		<div className="special-card-body">
			<div className="product-desc">
				<div className="product-title">
					<SpecialName name={item.name} className="product-title__name" />
				</div>
				{item.location
					&& <div className="map-point">
						<i className="fa fa-map-marker" />
						<WithPathLink prefix="clinics" path={item.location.portalPathEn}>
							<SpecialLocation text={locationName} />
						</WithPathLink>
					</div>
				}
				{item.description
					? <div className="product-caption" title={item.description} />
					: null
				}
				<div className="special-price__container">
					<span className="special-price mr10">{getMoneyString(item.price)}</span>
					<span className="special-original-price">{getMoneyString(item.originalPrice)}</span>
				</div>
				<div className="product-desc__btn-wrapper" onClick={(e) => e.preventDefault()}>
					<AddToCartButton special={item} />
				</div>
			</div>
		</div>
	</>;
};

const SpecialCard: React.FC<SpecialCardProps> = ({ item, location = undefined, lazyLoad = true }) => {
	const [open, setOpen] = React.useState<boolean>(false);

	return <div className="special-card__link special-card">
		<SpecialModal id={item.id} open={open} setOpen={setOpen} />
		<div className="special-card__inner">
			{item.path && <NavLink className="special-card__link" to={`/specials/${item.path}`} />}
			<SpecialCardContent setOpen={setOpen} item={item} location={location} lazyLoad={lazyLoad} />
		</div>
	</div>;
};

export default SpecialCard;
