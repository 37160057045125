import * as React from 'react';

import loadable from '@loadable/component';

import RegularChatMessage from '@common/react/components/Chat/RegularMessagePlugin/RegularChatMessage';
import { ChatPlugin } from '@common/react/components/Chat/Chat';
import { imageExtensions } from '@common/react/components/Chat/Chats';
import { loadableDelay } from '@common/react/loadable/loadableSettings';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

const params = {
	fallback: <Loader />,
};

const TextChatMessage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TextChatMessage" */ '@common/react/components/Chat/TextChatMessage')), params);

const Image: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ className, ...props }) => {
	const [error, setError] = React.useState(false);
	return <img
		className={`chat-file-tag-image ${error ? '' : 'chat-file-tag-image__bordered'}`}
		{...props}
		onError={(e) => {
			props.onError && props.onError(e);
			setError(true);
		}}
		alt={props.alt}
	/>;
};

export const RegularMessagePlugin : ChatPlugin = {
	message: {
		render: ({ message, contacts, onImageClick }) =>
			<RegularChatMessage
				handleLinks={!message.createdViaNotification && !message.notificationId}
				message={message}
				onImageClick={onImageClick}
				contacts={contacts}
			/>,
		lastMessage: ({ message, chat, userId }) => {
			const { state: { basePath } } = useChatSettingsProviderContext();

			const currentUser = message.userId === userId ? 'You: ' : '';
			if (message.text) {
				return <TextChatMessage
					text={(currentUser) + message.text}
					contacts={chat.contacts}
					withLink={false}
					className="chat-list__item-last-message"
				/>;
			}

			const fileCount = message.files.length;
			const images = message.files.filter((f) => imageExtensions.includes(f.file.extension?.toLowerCase()));

			if (images.length) {
				return <div className="chat-list__item-last-message">
					{currentUser}
					{images.map((image) => <Image
						key={image.file.id}
						src={`${basePath}${image.file.src}`}
						alt={image.file.name}
						className="chat-file-tag-image"
						title={image.file.name}
					/>)}
				</div>;
			}

			return <div className="chat-list__item-last-message">
				{currentUser}
				<i className="fa fa-file" style={{ marginRight: 5, marginLeft: currentUser ? 5 : 0 }} />
				{fileCount ? fileCount === 1 ? 'sent a file' : 'sent files' : ''}
			</div>;
		},
		notification: ({ message, withRemoteId, contacts }) => {
			return <RegularChatMessage handleLinks={false} message={message} contacts={contacts} />;
		},
	},
};
