import * as React from 'react';

import Upload from 'antd/lib/upload';

import Button from '@common/react/components/Forms/Button';
import { FileType, FileInterface } from '@common/typescript/objects/FileInterface';
import SimpleLoader from '@common/react/components/UI/SimpleLoader/SimpleLoader';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

interface BaseFileProps {
	type: string;
	objectId: number;
	onError?: (error: string) => void;
	fileType?: FileType;
	infoMessage?: string;
	asButton?: boolean;
	accept?: string;
	buttonCaption?: string | JSX.Element;
	buttonClassName?: string;
	errorClassName?: string;
	disabled?: boolean;
	multiple?: boolean;
	noRelation?: boolean; // Whether to create relation for uploaded file or not
	additionalData?: object;
	transformData?: (data: FormData) => FormData; // Transform data before sending to server
	request?: string;
}

interface FileProps extends BaseFileProps {
	update: (data: FileInterface) => void;
	showError?: boolean;
	customRequest?: (params: any) => Promise<any>;
	beforeRequest?: (action, argument, setState) => void;
	maxSize?: number;
}

interface CustomRequestParams {
	action: any;
	data: any;
	file: any;
	filename: any;
	headers: any;
	onError: any;
	onProgress: any;
	onSuccess: any;
	withCredentials: any;
}

interface UploadFileParams {
	file: any;
	type: string;
	objectId: number | null;
	fileType: FileType;
	additionalData?: object;
	headers?: any;
	request?: string;
}

const isJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const uploadFile = (params: UploadFileParams, transformData?: (data: FormData) => FormData): Promise<any> => {
	const formData = new FormData();

	formData.append('file', params.file);
	formData.append('objectType', params.type);
	formData.append('fileType', params.fileType.toString());

	if (params.objectId !== null) {
		formData.append('objectId', params.objectId.toString());
	}

	if (params.additionalData) {
		const data = params.additionalData;
		Object.keys(data).forEach((key) => formData.append(`data[${key}]`, data[key].toString()));
	}

	return fetch(params.request || 'fineUploader', {
		credentials: 'same-origin',
		method: 'POST',
		headers: params.headers,
		body: transformData ? transformData(formData) : formData,
	})
		.then((response) => response.text())
		.then((response) => {
			if (!isJson(response)) {
				throw new Error('file size error');
			}
			return JSON.parse(response);
		})
		.then(((response) => {
			if (!response.success) throw response.response;
			return response.response;
		}))
		.catch((error) => {
			console.log(error.message);
			throw error;
		});
};

const File: React.FC<FileProps> = (props) => {
	const {
		infoMessage = 'Image size must not exceed 1 Mb',
		asButton = false,
		accept = '',
		buttonCaption = 'Upload',
		showError = true,
		type,
		objectId,
		fileType = FileType.Avatar,
		additionalData,
		noRelation,
		transformData,
		request,
		buttonClassName,
		maxSize = 100,
	} = props;
	const [state, setState] = React.useState<{ error: null | string, isLoading: boolean }>({
		isLoading: false,
		error: null,
	});

	const customRequest = (argument) => {
		const { file, headers } = argument as CustomRequestParams;

		const params = {
			file,
			fileType,
			objectId,
			type,
			additionalData,
			headers,
			request,
		};

		if (noRelation) {
			headers['No-Relation'] = 'true';
		}

		setState({
			isLoading: true,
			error: null,
		});

		const action = () => (props.customRequest ? props.customRequest(params) : uploadFile(params, transformData))
			.then((data: FileInterface) => {
				setState((prev) => ({ ...prev, isLoading: false }));
				props.update(data);
			}).catch((error) => {
				setState({
					isLoading: false,
					error: error.message,
				});

				if (props.onError) {
					props.onError(error.message);
				}
			});
		if (props.beforeRequest) {
			props.beforeRequest(action, argument, (newState) => setState((prev) => ({ ...prev, ...newState })));
		} else {
			action();
		}
	};

	let uploadButton;

	if (asButton) {
		uploadButton = (<>
			<Button
				type="button"
				className={buttonClassName}
				isLoading={state.isLoading}
				disabled={props.disabled}
			>
				{buttonCaption}
			</Button>
			{showError && state.error
				&& (
					<div
						className={props.errorClassName ? props.errorClassName : 'file-upload-error'}
					>
						{state.error}
					</div>
				)
			}
		</>);
	} else {
		uploadButton = (
			<>
				{state.isLoading ? <SimpleLoader /> : <i className="fa fa-plus" />}
				<div className="ant-upload-text">{buttonCaption}</div>
				<div className="ant-upload-info">{state.error || infoMessage}</div>
			</>
		);
	}

	return <Upload
		className={`avatar-uploader ${state.error ? 'avatar-uploader_error' : ''}`}
		listType={props.asButton ? 'text' : 'picture-card'}
		showUploadList={false}
		accept={accept}
		beforeUpload={(file) => {
			if (file.size > maxSize * 10 ** 6) {
				setState((prev) => ({
					...prev,
					error: `max size is ${maxSize}Mb`,
				}));
			}
			return true;
		}}
		customRequest={customRequest}
		disabled={props.disabled}
		multiple={props.multiple}
	>
		{uploadButton}
	</Upload>;
};

interface CustomFileProps extends BaseFileProps {
	onUploadType: string;
	onUpload: (data: any) => void;
}

export const CustomFile: React.FC<CustomFileProps> = (props) => {
	const {
		onUploadType,
	} = props;
	const {
		request,
	} = useApplicationContext();

	const update = (data: FileInterface) => {
		request(onUploadType, {
			fileId: data.id,
		}).then((response: any) => {
			// response cast to any for new typescript support
			const image = {
				...response,
				file: data,
			};

			props.onUpload(image);
		}).catch((err) => {
			console.log(err);
		});
	};

	return <File update={update} {...props} />;
};

export default File;
