import * as React from 'react';

import useRequest from '@common/react/hooks/useRequest';
import File from '@common/react/components/Forms/Files/File';
import { FileType } from '@common/typescript/objects/FileInterface';
import Colorbox from '@common/react/components/UI/Colorbox/Colorbox';
import { useModal } from '@common/react/components/Modal/ModalContextProvider';

import '@common/react/scss/components/avatar.scss';

interface AvatarProps {
	type: string;
	object: any;
	update?: (result: OnAvatarUploadResult, data?: any) => void;
	className?: string;
	buttonCaption?: string;
	infoMessage?: string;
	fileType?: FileType;
	property?: string;
	propertyOrigin?: string;
	confirmDelete?: boolean;
	additionalData?: object;
	noRelation?: boolean; // Whether to create relation for uploaded file or not
	onError?: (error: any) => void;
	onImageError?: (error: any) => void;
	requestUpload?: string;
	removeRequest?: string;
	removeData?: any;
	customRequest?: (params: any) => Promise<any>;
	firstName?: string;
	lastName?: string;
	imageSkeleton?: string;
	color?: string;
	withModal?: boolean;
	readonly?: boolean;
	showOpenIcon?: boolean;
	thumbClassName?: string;
	showDownload?: boolean;
}

export interface OnAvatarUploadResult {
	avatar: string;
	originalAvatar: string;
}

export interface FileAvatar {
	file: { src: string };
}

const AvatarWithColorbox: React.FC<AvatarProps> = (props) => {
	const {
		className = '',
		propertyOrigin = 'originalAvatar',
		removeData,
		object,
		type,
		removeRequest,
		onError,
		update,
		buttonCaption,
		infoMessage,
		fileType = FileType.Avatar,
		property = 'avatar',
		confirmDelete = true,
		additionalData,
		customRequest,
		noRelation = false,
		firstName = object?.firstName,
		lastName = object?.lastName,
		imageSkeleton,
		color = object?.color,
		withModal = true,
		readonly,
		showOpenIcon = true,
		thumbClassName = 'thumbnail avatar-component__thumb',
		showDownload = true,
		onImageError,
		requestUpload,
	} = props;
	const [colorBoxIndex, setColorBoxIndex] = React.useState<number | null>(null);
	const [error, serError] = React.useState(false);
	const request = useRequest();
	const { openDeleteConfirm } = useModal();

	React.useEffect(() => {
		serError(false);
	}, [object[property]]);

	const removeFileUpload = () => {
		const data = removeData || {
			src: object[propertyOrigin],
			deleted: true,
			objectType: type,
			objectId: object.id,
		};

		request(removeRequest || 'uploadedFile', data)
			.then(() => {
				// set empty avatar in finally, so user can reload avatar even if something goes wrong
			})
			.catch((error) => {
				onError?.(error);
			}).finally(() => {
				update?.({ avatar: '', originalAvatar: '' });
			});
	};

	const onConfirmDelete = () => {
		openDeleteConfirm({
			onOk: () => {
				removeFileUpload();
			},
			okButtonProps: {
				'data-confirm': 'all',
			},
		});
	};

	const onUpdate = (result: any) => {
		update?.({ avatar: result.thumb, originalAvatar: result.src }, result);
	};

	const openAvatar = () => {
		setColorBoxIndex(0);
	};

	const closeAvatar = () => {
		setColorBoxIndex(null);
	};

	const handleError = (e) => {
		serError(true);
		onImageError?.(e);
	};

	const fileAvatar = [{ file: { src: object[propertyOrigin] } }];
	const fileSrc = object[propertyOrigin] || object[property];
	const showImage = (object[property] || imageSkeleton) && (!error || (!firstName && !lastName && imageSkeleton));

	const Thumb = (<div className={thumbClassName} style={{ backgroundColor: !showImage ? color || '' : '' }}>
		{showImage
			? (
				<img
					onError={handleError}
					className={`avatar-img ${withModal ? 'may-open' : ''}`}
					onClick={openAvatar}
					src={error ? imageSkeleton : object[property] || imageSkeleton}
					alt={property}
					title={`${firstName} ${lastName}`}
				/>
			)
			: (
				<span
					className={`avatar-user-initials ${color ? 'color-filter' : ''}`}
					style={{ color: color || '' }}
					title={`${firstName || ''} ${lastName || ''}`}
				>
					{firstName?.length ? firstName[0] : 'N'}
					{lastName?.length ? lastName[0] : typeof lastName === 'string' ? '' : 'N'}
				</span>
			)
		}
		{!readonly && <i
			className="avatar-component__remove fa fa-times"
			onClick={(e) => (confirmDelete ? onConfirmDelete() : removeFileUpload())}
		/>}
		{showOpenIcon && object[propertyOrigin] && !error && <i className="avatar-component__zoom fa fa-search" onClick={openAvatar} />}
		{fileSrc && showDownload ? (
			<a
				className="avatar-component__download"
				href={fileSrc}
				target="_blank"
				download
				title="Download"
				rel="noreferrer"
			>
				<i className="fa fa-download" />
			</a>
		) : null}
	</div>);

	return <div className={`avatar-component ${className}`} style={{ backgroundColor: !showImage ? color || '' : '' }}>
		{object[property] || readonly
			? Thumb
			: (
				<File
					buttonCaption={buttonCaption}
					infoMessage={infoMessage}
					fileType={fileType}
					objectId={object.id}
					type={type}
					request={requestUpload}
					update={onUpdate}
					accept="image/png, image/jpeg, .heic"
					additionalData={additionalData}
					customRequest={customRequest}
					noRelation={noRelation}
					beforeRequest={(action, argument, setState) => {
						const { file } = argument;
						if (file.type.includes('image') || file.name.includes('.heic')) {
							action();
						} else {
							setState({
								isLoading: false,
								error: 'Error file type',
							});
						}
					}}
				/>
			)
		}
		{withModal && object[propertyOrigin] && !error && <Colorbox files={fileAvatar} defaultIdx={colorBoxIndex} onCancel={closeAvatar} />}
	</div>;
};

export default AvatarWithColorbox;
