import React from 'react';

import Tag from 'antd/lib/tag';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import { solveWorkingHours } from '@commonTuna/react/utils/workingHours/workingHours';
import { numberWithComma } from '@commonTuna/react/utils/NumberWithComma/NumberWithComma';

import { getLocationAddress, showTime } from '@app/utils/Utils';
import Stars from '@app/components/UI/Stars/Stars';

interface LocationNodeProps {
	item: any;
	hideLink?: boolean;
	addressAsLink?: boolean;
	showRate?: boolean;
}

const LocationInfo: React.FC<LocationNodeProps> = ({
	item, hideLink, addressAsLink, showRate,
}) => {
	const address = React.useMemo(() => getLocationAddress(item), []);
	const link = React.useMemo(() => {
		let res = `https://maps.google.com/maps/search/?api=1&query=${address}`;
		if (typeof window !== 'undefined') {
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				res = `maps://maps.google.com/maps/search/?api=1&query=${address}`;
			}
		}
		return res;
	}, [item]);

	const workingHours = React.useMemo(() => {
		if (!item.workingHours) return [];
		return solveWorkingHours(item.workingHours);
	}, [item.workingHours]);

	return <div className="clearfix">
		{showRate ? <div className="stars pull-right ml10" style={{ lineHeight: '28px' }}>
			<strong className="mr10">
				<Stars value={item.averageReviewRate < 1 ? 1 : item.averageReviewRate} />
			</strong>
			{numberWithComma(item.averageReviewRate)}
			{' '}
			(
			{item.totalReviewCount || 0}
			)
		</div> : null}
		{address ? <span style={{ lineHeight: showRate ? '28px' : undefined }}>
			<strong>
				Address:&nbsp;
			</strong>
			{addressAsLink ? <a style={{ cursor: 'pointer' }} href={link}>{address}</a> : address}
		</span> : null}
		{workingHours.length ? <>
			<br />
			<strong>
				Working Hours:&nbsp;
			</strong>
			{workingHours.map((wh) => <React.Fragment
				key={wh.id}
			>
				<Tag color="orange">
					<strong>
						{wh.title}
						:
					</strong>
					{' '}
					{showTime(wh.startTime)}
					{' '}
					-
					{' '}
					{showTime(wh.endTime)}
				</Tag>
			</React.Fragment>)}
		</> : null}
		{hideLink ? null : <div className="pull-right">
			<LinkWithPrevLocation
				className="pull-right"
				to={{
					pathname: `/clinics/${item.portalPathEn}`,
				}}
			>
				More About
				{' '}
				{item.nameEn}
				{' '}
				Clinic
			</LinkWithPrevLocation>
		</div>}
	</div>;
};

export default LocationInfo;
