import * as React from 'react';

import message from 'antd/lib/message';
import { FormikProps } from 'formik';

import {
	ChatFormButtonsProps, NewMessage, ChatPlugin, ChatPlugins,
} from '@common/react/components/Chat/Chat';
import File from '@common/react/components/Forms/Files/File';
import { FileType } from '@common/typescript/objects/FileInterface';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

const FileButton: React.FC<{ formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps }> = ({ formikBag, props }) => {
	const context = useChatSettingsProviderContext();
	const { state: { plugins } } = context;
	const request = plugins[ChatPlugins.Files]?.options?.request;

	return <File
		multiple
		request={request}
		buttonCaption={<i className="fa fa-paperclip" style={{ fontSize: 16, margin: 0, verticalAlign: 'text-top' }} />}
		asButton
		objectId={-1}
		type="chatMessage"
		update={props.onUploadFile}
		onError={(err: any) => {
			message.error(typeof err === 'object' ? err.message : err);
		}}
		fileType={FileType.File}
		buttonClassName="btn btn-sm btn-default chat-message-item"
		showError={false}
	/>;
};

export const FilePlugin: ChatPlugin = {
	formButton: (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) => <FileButton formikBag={formikBag} props={props} />,
	options: {
		request: 'fineUploader',
	},
};
